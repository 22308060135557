@media (min-width: 768px) {
    .flex-za-veliki {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

/* Ensures the phone number does not break */
.no-break {
    white-space: nowrap;
    font-weight: bold;
    text-decoration: none;
    color: #31a957; /* WhatsApp green */
    margin-left: 10px;
}
